import React from 'react'

function KeyFeatures() {
    return (
        <>
            <section>
                <div className="container">
                    <div className='key-features'>
                        <img src="../images/jpg/key-features.jpg" className='d-none d-md-block' alt="" />
                        <img src="../images/jpg/key-features-sm.webp"  className='d-md-none' alt="" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default KeyFeatures