import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';




function Header() {
    const location = useLocation();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activeLink, setActiveLink] = useState('Home');

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    return (
        <>
            <header>
                <div className="container">
                    <Navbar className='py-1'>
                        <Container>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <Navbar.Brand href="/">
                                    <img src="../images/logo/logo.svg" className='header-logo' alt="" />
                                </Navbar.Brand>
                                <Navbar.Collapse id="basic-navbar-nav" className='d-none'>
                                    {/* <Nav className="header-menu-list">
                                        <Nav.Link to="/" activeClassName="active">
                                            Home
                                        </Nav.Link>
                                        <Nav.Link to="" activeClassName="active">
                                            Terms & Condition
                                        </Nav.Link>
                                    </Nav> */}

                                    <nav className="header-menu-list">
                                        <NavLink exact to="/" className="text-decoration-none" activeClassName="active">
                                            Home
                                        </NavLink>
                                        <NavLink exact to="/terms-conditions" className="text-decoration-none" activeClassName="active" >
                                            Terms & Conditions
                                        </NavLink>
                                    </nav>
                                </Navbar.Collapse>

                                <div className='d-flex align-items-center gap-3'>
                                    <Link className='secondary-btn text-decoration-none' to="/contact-us">Contact Us</Link>
                                    <div className='d-md-none' onClick={handleShow}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 20"
                                            width="40"
                                            height="40"
                                        >
                                            <path
                                                fill="#FFFFFF" // or fill="white"
                                                d="M21,6H3A1,1,0,0,1,3,4H21a1,1,0,0,1,0,2Z"
                                            />
                                            <path
                                                fill="#FFFFFF" // or fill="white"
                                                d="M21,11H3a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z"
                                            />
                                            <path
                                                fill="#FFFFFF" // or fill="white"
                                                d="M21,16H3a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </Navbar>
                </div>
            </header>

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header className='border-bottom' closeButton>
                    <Offcanvas.Title>
                        <img src="../images/logo/logo.svg" className='header-logo' alt="" />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="sidebar-menu">
                        <div className='border-bottom mb-3'>
                            <Nav.Link href="/" className={location.pathname === '/' ? 'active' : ''} onClick={handleClose}>Home</Nav.Link>
                        </div>
                        <div className='border-bottom mb-3'>
                            <Nav.Link href="/terms-condition" className={location.pathname === '/terms-condition' ? 'active' : ''} onClick={handleClose}>Terms & Conditions</Nav.Link>
                        </div>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Header