export const Validation = (values) => {
  let errors = {};

  // Validate full_name field
  if (!values?.first_name) {
    errors.first_name = "First name field is required.";
  } else if (values?.first_name?.trim()?.length === 0) {
    errors.first_name = "First name cannot be empty or contain only spaces.";
  } else if (values?.first_name?.length < 2) {
    errors.first_name = "First Name should be minimum 2 characters.";
  }

  // Validate full_name field
  if (!values?.last_name) {
    errors.last_name = "Last name field is required.";
  } else if (values?.last_name?.trim()?.length === 0) {
    errors.last_name = "Last name cannot be empty or contain only spaces.";
  } else if (values?.last_name?.length < 2) {
    errors.last_name = "Last Name should be minimum 2 characters.";
  }

  // Validate email field
  if (!values?.email) {
    errors.email = "Email field is required.";
  } else if (!/\S+@\S+\.\S+/.test(values?.email)) {
    errors.email = "Invalid email format. Please provide a valid email address.";
  }

  // Validate contact_no field
  if (!values?.contact_no) {
    errors.contact_no = "Contact number field is required.";
  }
  else if (values?.contact_no?.trim()?.length === 0) {
    errors.contact_no = "Contact cannot be empty or contain only spaces.";
  }

  // Validate message field
  if (!values?.message) {
    errors.message = "Message field is required.";
  } else if (values?.message?.trim()?.length === 0) {
    errors.message = "Message cannot be empty or contain only spaces.";
  } else if (values?.message?.trim()?.length < 2) {
    errors.message = "Message should be minimum 2 characters.";
  }

  // instagram_url field
  if (!(values?.instagram_url)) {
    errors.instagram_url = "Instagram url fields is require.";
  } else if (!Boolean(values?.instagram_url?.startsWith('http://') || values?.instagram_url?.startsWith('https://'))) {
    errors.instagram_url = "The input does not have a valid url format. Please enter a valid instagram URL."
  }

  // facebook_url field
  if (!(values?.facebook_url)) {
    errors.facebook_url = "Facebook url fields is require.";
  } else if (!Boolean(values?.facebook_url?.startsWith('http://') || values?.facebook_url?.startsWith('https://'))) {
    errors.facebook_url = "The input does not have a valid url format. Please enter a valid facebook URL."
  }

  // website_url field
  if (!(values?.website_url)) {
    errors.website_url = "Website fields is require.";
  } else if (!Boolean(values?.website_url?.startsWith('http://') || values?.website_url?.startsWith('https://'))) {
    errors.website_url = "The input does not have a valid url format. Please enter a valid website URL."
  }

  return errors;
};
