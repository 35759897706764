import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function Section1({ explore }) {
    return (
        <section className='hero-main'>
            <div id='hero-sec'>
                <Container>
                    <Row>
                        <Col xs={12} md={11} lg={8} xl={6}>
                            <h1 className='hero-title'>
                                {explore.title}
                            </h1>
                            <p className='hero-description'>
                                {explore.description}
                            </p>
                            <button className='primary-btn'>Download the App</button>
                        </Col>
                        {/* <Col xs={12} md={11} lg={8} xl={6}>
                            <h1 className='hero-title'>
                                Explore A Greener Lifestyle With Our Plant-Based App
                            </h1>
                            <p className='hero-description'>
                                Where luxury meets comfort in a haven designed for beauty and relaxation. Our expert stylists are dedicated to transforming your look and enhancing your natural beauty with personalized services
                            </p>
                            <button className='primary-btn'>Explore More</button>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default Section1;
