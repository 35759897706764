import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css';
import './assets/font/css2.css';
import Index from "./pages/Index";
import ContactUs from "./pages/ContactUs";
import TermsConditions from "./pages/terms-conditions";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="*" element={<Navigate  to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
