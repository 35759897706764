import React, { useState } from 'react';
import { Container, Row, Col, Card, NavLink } from 'react-bootstrap';
import { getImageBaseUrl } from '../env.Config';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function Section5({ chapters }) {

    const [show, setShow] = useState(false);
    const [myChapter, setMyChapter] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = (id, index) => {
        console.log(index, "index");
        if (index !== 3) {
            setMyChapter(chapters?.chapters?.find(e => e._id == id))
        } else {
            setMyChapter(null)
        }

        setShow(true);
    }


    const finalContent = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
       
        <style>
        .custom-html body {
            padding: 20px;
        }
        .custom-html figure img {
            width: 100%;
            height: 100%;
        }
        .custom-html p {
            word-break: break-all;
            font-size: 18.300px;
            color: #555;
            font-weight: 500;
        }
        .custom-html ul {
            padding: 0px;
        }
        .custom-html ul {
          padding: 0px;
      }
      .custom-html blockquote {
        border-left: 5px solid #ccc;
        font-style: italic;
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
      .custom-html strong {
        font-weight: 900;
      }
         .custom-html h1,
         .custom-html h2,
         .custom-html h3,
         .custom-html h4,
         .custom-html h5,
         .custom-html h6 {
            margin-top: 15px;
        }
        
        .custom-html ul li {
          font-size: 18.300px;
          color: #555;
          font-weight: 500;
          margin-left: 1.5rem !important;
        }
  
        .custom-html body::-webkit-scrollbar{
            display:none;
        }
    </style>
  
    </head>
    <body>
    ${myChapter?.content}
    </body>
    </html>
    `;

    return (
        <section>
            {/* <Container> */}
                <h2 className='sec-title pt-0 pt-sm-4 pt-md-4 '>
                    <span className='text-underline'> Course </span> 
                </h2>
                <Row className='mt-4 mt-md-4 pb-4 g-4'>
                    {chapters?.chapters?.map((recipe, index) => (
                        <Col xs={12} md={6} lg={4} className='mb-2 mb-lg-0' key={index}>
                            <Card onClick={() => handleShow(recipe._id, recipe.chapter_index)} className='cursor-pointer'>
                                <Card.Body className='recipes-box chapter-list-image' style={{ backgroundImage: `url(${getImageBaseUrl()}/${chapters.lecture.image})` }}>
                                    {/* <div className="recipes-box">
                                        <img src={getImageBaseUrl() + "/" + recipe.recipes_images} className='recipes' alt={recipe.title} />
                                    </div> */}
                                    <div>
                                        <h4>{recipe.title}</h4>
                                        <p>{recipe.description}</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}

                </Row>
            {/* </Container> */}

            {/* View chapter modal */}
            <Modal
                size="lg"
                show={show} onHide={handleClose}
                centered
                className="modal-main info-modal pl-0"
            >
                <Modal.Header
                    className="d-block position-relative border-0 pt-4 px-4"
                    closeButton
                >
                    {myChapter !== null &&
                        <Modal.Title className="text-center">View Chapter</Modal.Title>}
                    {myChapter !== null &&
                        <div className="d-flex justify-content-center text-center mt-2">
                            <img src="./images/png/line.svg" className="s-img" />
                        </div>
                    }



                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
                    <Row className="justify-content-center">
                        <Col className="col-11 px-0">
                            <div className="d-flex justify-content-center">
                                <div className="my-4">
                                    {
                                        myChapter !== null ?
                                            <div
                                                className="custom-html"
                                                dangerouslySetInnerHTML={{ __html: finalContent }}
                                            />
                                            :
                                            <div >
                                                <div className='text-center p-3 p-sm-4'>

                                                    <img src='./images/png/logo.png' className='model-logo' alt='' />
                                                    <h5 className='my-3'>Get the full experience on <br /> the app</h5>
                                                    <p>Join our community to discover new plant-based products, track your dietary habits, and connect with like-minded enthusiasts.</p>
                                                    {/* <p>Follow you favoritevendor accounts,
                                                        explore new product and message the <br /> vendor</p> */}
                                                    <div className='d-flex align-items-center justify-content-center gap-2 mt-4 app-download'>
                                                        <NavLink href='https://play.google.com/store/apps/details?id=com.pgac0cdz2yd.pwxpecxgoapp&pli=1' target='_blank'>
                                                            <img src='./images/png/playstore.png' alt='' />
                                                        </NavLink>
                                                        <NavLink href='https://apps.apple.com/us/app/the-plant-based-switch/id6444078958' target='_blank'>
                                                            <img src='./images/png/app.png' alt='' />
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </section>


    );
}

export default Section5;
