import React from 'react';
import "./Loaders.css";

const Loader = ({ loading }) =>
    loading ||
    (
    <div className='loader-white'>
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
)
export default Loader