import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import Header from "../auth/Header";
import Footer from "../auth/Footer";
import SingleError from "../components/SingleError";

import axios from "axios";
import { getServerURL } from "../env.Config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Validation } from "../components/Validation";
import { useNavigate } from "react-router-dom";

function ContactUs() {
    const serverURL = getServerURL();
    const [errors, setErrors] = useState({});
    const [submitCount, setSubmitCount] = useState(0);
    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Plant base || Contact Us ";
    }, []);

    const [formData, setFormData] = useState({
        action: "post-contact",
        first_name: "",
        last_name: "",
        email: "",
        contact_no: "",
        message: "",
        instagramProfile: "",
        facebookProfile: "",
        website: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (submitCount > 0) {
            let validationErrors = Validation({ ...formData, [name]: value });
            setErrors(validationErrors);

            if (Object.keys(validationErrors)?.length === 0) {
                delete errors[name];
            }
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePhoneChange = (value) => {
        // setPhone(value);
        if (submitCount > 0) {
            let validationErrors = Validation({ ...formData, ["contact_no"]: value });
            setErrors(validationErrors);

            if (Object.keys(validationErrors)?.length === 0) {
                delete errors["contact_no"];
            }
        }
        setFormData({
            ...formData,
            contact_no: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitCount(1);

        const updatedValues = { ...formData };
        let validationErrors = Validation(updatedValues);
        setErrors(validationErrors);

        if (Object.keys(validationErrors)?.length) {
            Object.entries(validationErrors)?.map(([key], i) => {
                if (i == 0)
                    document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
            });
        }

        if (Object.keys(validationErrors).length === 0) {
            axios
                .post(`${serverURL}user/landing-page`, formData)
                .then((res) => {
                    console.log(res?.data);
                    if (res?.data?.success) {
                        if (typeof res?.data?.message === "string")
                            setSuccessMsg(res?.data?.message);
                        else setSuccessMsg("Contact information submitted successfully.");
                        document
                            .getElementById("top")
                            ?.scrollIntoView({ behavior: "smooth" });
                        setFormData({
                            first_name: '',
                            last_name: '',
                            email: '',
                            contact_no: '',
                            message: '',
                            instagramProfile: '',
                            facebookProfile: '',
                            website: ''
                        });
                    }
                })
                .catch((e) => {
                    if (e?.code === "ERR_NETWORK") {
                        setErrorMsg("Network Error: Unable to connect to the internet.")
                    } else
                        setErrorMsg("Oops! It seems that something went wrong while processing your request. Please try again later.")
                    document
                        .getElementById("top")
                        ?.scrollIntoView({ behavior: "smooth" });
                });
        }
    };

    useEffect(() => {
        if (successMsg) {
            const timeout = setTimeout(() => {
                setSuccessMsg(false);
                navigate('/')
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [successMsg]);

    useEffect(() => {
        if (errorMsg) {
            const timeout = setTimeout(() => {
                setErrorMsg(false)
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [errorMsg]);

    return (
        <>
            <Header />
            <section>
                <div className="container">
                    <Row className="d-flex justify-content-center mt-5">
                        <Col md={12} lg={9} xl={6}>
                            <Card id="top">
                                <Card.Body>
                                    {Boolean(successMsg) && (
                                        <div
                                            className="alert alert-success d-flex align-items-center"
                                            role="alert"
                                        >
                                            <svg
                                                className="bi flex-shrink-0 me-2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="#28a745"
                                                    d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm5.647 8.95l-6.624 9.373c-.195.277-.504.444-.834.444-.331 0-.64-.167-.834-.444l-3.75-5.311a1.25 1.25 0 0 1 .417-1.739c.468-.291 1.084-.147 1.417.35l2.9 4.105 5.773-8.184c.312-.443.926-.56 1.369-.248.444.312.561.927.248 1.369z"
                                                />
                                            </svg>
                                            <div>{successMsg}</div>
                                        </div>
                                    )}
                                    {Boolean(errorMsg) && (
                                        <div className="alert alert-danger d-flex align-items-center" role="alert">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                fill="#dc3545"
                                                className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                                viewBox="0 0 16 16"
                                                role="img"
                                                aria-label="Warning:"
                                            >
                                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                            </svg>
                                            <div>
                                                {errorMsg}
                                            </div>
                                        </div>

                                    )}
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6} className="mb-3" id="first_name">
                                                <Form.Group controlId="first-name">
                                                    <Form.Label>First Name*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter your first name"
                                                        name="first_name"
                                                        value={formData?.first_name || ""}
                                                        onChange={handleChange}
                                                    // required
                                                    />
                                                </Form.Group>
                                                <SingleError error={errors?.first_name} />
                                            </Col>
                                            <Col md={6} className="mb-3" id="last-name">
                                                <Form.Group controlId="last_name">
                                                    <Form.Label>Last Name*</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter your last name"
                                                        name="last_name"
                                                        value={formData?.last_name || ""}
                                                        onChange={handleChange}
                                                    // required
                                                    />
                                                </Form.Group>
                                                <SingleError error={errors?.last_name} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} className="mb-3" id="email">
                                                <Form.Group controlId="email-id">
                                                    <Form.Label>Email Id*</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter your email"
                                                        name="email"
                                                        value={formData?.email || ""}
                                                        onChange={handleChange}
                                                    // required
                                                    />
                                                </Form.Group>
                                                <SingleError error={errors?.email} />
                                            </Col>
                                            <Col md={6} className="mb-3" id="contact_no">
                                                <Form.Group controlId="contact">
                                                    <Form.Label>Contact No*</Form.Label>
                                                    <PhoneInput
                                                        country={"in"}
                                                        value={formData?.contact_no || ""}
                                                        onChange={handlePhoneChange}
                                                        inputProps={{
                                                            name: "contact_no",
                                                            required: true,
                                                            placeholder: "Enter your contact number",
                                                        }}
                                                    />
                                                    <SingleError error={errors?.contact_no} />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Form.Group
                                            controlId="message-text"
                                            className="mb-3"
                                            id="message"
                                        >
                                            <Form.Label>Message*</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter your message"
                                                name="message"
                                                value={formData?.message || ""}
                                                onChange={handleChange}
                                            // required
                                            />
                                            <SingleError error={errors?.message} />
                                        </Form.Group>

                                        <Form.Group
                                            controlId="instagram-url"
                                            className="mb-3"
                                            id="instagram_url"
                                        >
                                            <Form.Label>Instagram Profile</Form.Label>

                                            <div className="form-control d-flex align-items-center gap-2 py-0">
                                                <img src="../images/png/Instagram.png" alt="" />
                                                <Form.Control
                                                    type="text"
                                                    className="px-0 border-0"
                                                    placeholder="Enter your Instagram profile URL"
                                                    name="instagram_url"
                                                    value={formData?.instagram_url || ""}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <SingleError error={errors?.instagram_url} />
                                        </Form.Group>

                                        <Form.Group
                                            controlId="facebook-url"
                                            className="mb-3"
                                            id="facebook_url"
                                        >
                                            <Form.Label>Facebook Profile</Form.Label>
                                            <div className="form-control d-flex align-items-center gap-2 py-0">
                                                <img src="../images/png/facebook.png" alt="" />
                                                <Form.Control
                                                    type="text"
                                                    className="px-0 border-0"
                                                    placeholder="Enter your Facebook profile URL"
                                                    name="facebook_url"
                                                    value={formData?.facebook_url || ""}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <SingleError error={errors?.facebook_url} />
                                        </Form.Group>

                                        <Form.Group
                                            controlId="website-url"
                                            className="mb-3"
                                            id="website_url"
                                        >
                                            <Form.Label>Website</Form.Label>
                                            <div className="form-control d-flex align-items-center gap-2 py-0">
                                                <img src="../images/png/website.png" alt="" />
                                                <Form.Control
                                                    type="text"
                                                    className="px-0 border-0"
                                                    placeholder="Enter your website URL"
                                                    name="website_url"
                                                    value={formData?.website_url || ""}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <SingleError error={errors?.website_url} />
                                        </Form.Group>

                                        <div className="d-flex justify-content-center">
                                            <button className="submit-btn" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ContactUs;
