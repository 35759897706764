import React, { useEffect, useState } from 'react'
import Header from '../auth/Header'
import Section1 from '../components/Section1'
import Section2 from '../components/Section2'
import Section3 from '../components/Section3'
import Section4 from '../components/Section4'
import Footer from '../auth/Footer'
import KeyFeatures from '../components/KeyFeatures'
import axios from "axios";
import { getServerURL } from '../env.Config'
import Loader from '../loader/Loader'


function Index() {
  const [data, setData] = useState({ explore: [], working: [], recipes: [], review: [] });
  const serverURL = getServerURL();
  const [loader,setLoader]=useState(true)

  useEffect(() => {
    document.title = "Plant base || Home";
  }, []);


  // Function to fetch quiz questions
  const getQuizes = async () => {
    try {
      // Define multiple requests as an array of axios calls
      const requests = [
        axios.post(`${serverURL}user/landing-page`, { action: 'explore-get' }),
        axios.post(`${serverURL}user/landing-page`, { action: 'working-get' }),
        axios.post(`${serverURL}user/landing-page`, { action: 'recipes-get' }),
        axios.post(`${serverURL}user/landing-page`, { action: 'review-get' }),
        axios.post(`${serverURL}user/landing-page`, { action: 'for-you-get' }),
        axios.post(`${serverURL}user/landing-page`, { action: 'get-chapters' }),

      ];

      // Use axios.all to send all requests concurrently
      const responses = await axios.all(requests);

      if(responses?.length){
        setLoader(false)
      }
      // Use the responses to set data for each category

      const newData = {
        explore: responses[0].data?.data,
        working: responses[1].data?.data,
        recipes: responses[2].data?.data,
        review: responses[3].data?.data,
        for_you: responses[4].data?.data,
        chapters: responses[5].data?.data,

      };

      // Set the new data
      setData(newData);

    } catch (error) {
      console.error(error);
      setLoader(false)
    }
  };


  // Use useEffect to call the getQuizes function on component mount
  useEffect(() => {
    getQuizes();
  }, []);


  return (

    <>
    {Boolean(loader) &&
    <Loader/>}
      <Header />
      <Section1 explore={data.explore} />
      <Section2 working={data.working} />
      <Section3 recipes={data.recipes} for_you={data.for_you} chapters={data.chapters} />
      <KeyFeatures />
      {/* <Section4 review={data.review} /> */}
      <Footer />
    </>
  )
}

export default Index