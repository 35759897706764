import React from 'react';
import { Container, Row, Col, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Section2({ working }) {

    return (
        <section className='py-4 py-md-5 border-bottom'>
            <Container>
                <h2 className='sec-title text-center'>
                    <span className='text-underline'>How</span> it Works
                </h2>
                <Row className='justify-content-center'>
                    <Col xs={11}>
                        <p className='works-sec-description'>{working?.description}</p>
                        {/* <p className='works-sec-description'>"Discover the power of plant-based living with our comprehensive app! Dive into learn pages filled with chapters on nutrition tips, diet plans, and daily exercises tailored for both men and women. Join our vibrant community to share experiences and stay motivated. Track your progress with features like push-up challenges, stress level management, weight and sugar tracking, water intake, and even menstrual cycle support. With personalized plans, achieving your health goals has never been easier!"</p> */}
                        <h4 className='get-it-on-title mt-4 mt-md-5 mb-4'>Get it on</h4>
                        <div className='d-flex flex-column flex-md-row justify-content-center gap-2 gap-md-3 pb-4 pb-md-5'>
                            <NavLink href='https://play.google.com/store/apps/details?id=com.pgac0cdz2yd.pwxpecxgoapp&pli=1' target='_blank'>
                                <img src='./images/png/playstore.png' alt='' className='d-flex justify-content-center d-md-block m-auto' />
                            </NavLink>
                            <NavLink href='https://apps.apple.com/us/app/the-plant-based-switch/id6444078958' target='_blank'>
                                <img src='./images/png/app.png' alt=''className='d-flex justify-content-center d-md-block m-auto'  />
                            </NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Section2;
