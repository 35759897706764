import React from 'react'

function Footer({mt}) {
    return (
        <>
            <footer className={`${mt===false && "mt-0"}`}>
                <div className="container">
                    <p><span>Copyright ©</span> 2024 The Plant-Based Switch </p>
                </div>
            </footer>
        </>
    )
}

export default Footer