import React from "react";
import Header from "../auth/Header";
import Footer from "../auth/Footer";
import { useLocation } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const TermsConditions = () => {
  const location = useLocation();
  console.log(location);

  return (
    <>
      <Header />

      <div className="mt-4 mx-4">
        <div
            className="vc_custom_1693993851234"
          style={{ paddingTop: "3%", paddingBottom: "3%" }}
        >
          <div className="px-5">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Terms & Conditions</Breadcrumb.Item>
                </Breadcrumb>
                <div className="wpb_wrapper">
                  <h2
                    style={{ textAlign: "left" }}
                    className="vc_custom_heading header-heading"
                  >
                    Terms & Conditions
                  </h2>
                </div>
          </div>
        </div>
      </div>


      <div className="container">
        <div
          class="custom-container-privacy"
          style={{
            paddingTop: "calc(100vw * 0.05)",
            paddingBottom: "calc(100vw * 0.05)",
          }}
        >
          <div class="row">
            <div
              class="col-sm-12 col-md-6 col-lg-4 no-extra-padding"
              style={{
                position: "relative",
                overflow: "visible",
                boxSizing: "borderBox",
                minHeight: "1px",
              }}
            >
              <div
                class="theiaStickySidebar"
                style={{
                  paddingTop: "0px",
                  paddingBottom: "1px",
                  position: "sticky",
                  top: "0px",
                  left: "351.5px",
                }}
              >
                <div class="vc_column-inner">
                  <div class="column-bg-overlay-wrap" data-bg-animation="none">
                    <div class="column-bg-overlay"></div>
                  </div>
                  <div class="wpb_wrapper">
                    <div class="wpb_text_column wpb_content_element ">
                      <div class="wpb_wrapper">
                        <div class="inner-left sticky-element-original element-is-sticky">
                          <div class="policy-left-sticky">
                            <div class="policy-head">
                              <h3>Table of Contents</h3>
                            </div>
                            <ul id="itemList" class="policy-left-menu">
                              <li class={`item ${(location?.hash === "#pickItem01" || location?.hash === "") && "active"}`}>
                                <a href="#pickItem01">
                                  1 – Intellectual Property Rights
                                </a>
                              </li>
                              <li class={`item ${location?.hash === "#pickItem02" && "active"}`}>
                                <a href="#pickItem02">
                                  2 – User Responsibilities
                                </a>
                              </li>
                              <li class={`item ${location?.hash === "#pickItem03" && "active"}`}>
                                <a href="#pickItem03">
                                  3 – Limitation of Liability
                                </a>
                              </li>
                              <li class={`item ${location?.hash === "#pickItem04" && "active"}`}>
                                <a href="#pickItem04">4 – Governing Law</a>
                              </li>
                              <li class={`item ${location?.hash === "#pickItem05" && "active"}`}>
                                <a href="#pickItem05">
                                  5 – Changes to Terms of Use
                                </a>
                              </li>
                              <li class={`item ${location?.hash === "#pickItem06" && "active"}`}>
                                <a href="#pickItem06">6 – Termination</a>
                              </li>
                              <li class={`item ${location?.hash === "#pickItem07" && "active"}`}>
                                <a href="#pickItem07">7 – Severability</a>
                              </li>
                              <li class={`item ${location?.hash === "#pickItem08" && "active"}`}>
                                <a href="#pickItem08">8 – Entire Agreement</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-sm-12 col-md-6 col-lg-8  privacy-content no-extra-padding"
              data-t-w-inherits="default"
              data-bg-cover=""
              data-padding-pos="all"
              data-has-bg-color="false"
              data-bg-color=""
              data-bg-opacity="1"
              data-hover-bg=""
              data-hover-bg-opacity="1"
              data-animation=""
              data-delay="0"
            >
              <div class="vc_column-inner">
                <div class="column-bg-overlay-wrap" data-bg-animation="none">
                  <div class="column-bg-overlay"></div>
                </div>
                <div class="wpb_wrapper">
                  <div
                    id="pickItem01"
                    data-midnight=""
                    data-column-margin="default"
                    class="wpb_row vc_row-fluid vc_row inner_row standard_section"
                    style={{ paddingTop: "0px", paddingBottom: "30px" }}
                  >
                    <div class="row-bg-wrap">
                      {" "}
                      <div class="row-bg"></div>{" "}
                    </div>
                    <div class="row_col_wrap_12_inner col span_12  left">
                      <div
                        class="vc_col-sm-12 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone instance-4"
                        data-t-w-inherits="default"
                        data-bg-cover=""
                        data-padding-pos="all"
                        data-has-bg-color="false"
                        data-bg-color=""
                        data-bg-opacity="1"
                        data-hover-bg=""
                        data-hover-bg-opacity="1"
                        data-animation=""
                        data-delay="0"
                      >
                        <div class="vc_column-inner">
                          <div
                            class="column-bg-overlay-wrap"
                            data-bg-animation="none"
                          >
                            <div class="column-bg-overlay"></div>
                          </div>
                          <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element  policy-text">
                              <div class="wpb_wrapper">
                                <h3>1 – Intellectual Property Rights</h3>
                                <p>
                                  The content and materials available on the Site,
                                  including but not limited to text, graphics,
                                  logos, images, audio clips, videos, data
                                  compilations, and software, are the property of
                                  Nafsika Inc. or its licensors and are protected
                                  by applicable intellectual property laws. You
                                  may not modify, reproduce, distribute, display,
                                  or perform the content and materials on the Site
                                  without the prior written consent of Nafsika
                                  Inc.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="pickItem02"
                    data-midnight=""
                    data-column-margin="default"
                    class="wpb_row vc_row-fluid vc_row inner_row standard_section   "
                    style={{ paddingTop: "0px", paddingBottom: "30px" }}
                  >
                    <div class="row-bg-wrap">
                      {" "}
                      <div class="row-bg"></div>{" "}
                    </div>
                    <div class="row_col_wrap_12_inner col span_12  left">
                      <div
                        class="vc_col-sm-12 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone instance-5"
                        data-t-w-inherits="default"
                        data-bg-cover=""
                        data-padding-pos="all"
                        data-has-bg-color="false"
                        data-bg-color=""
                        data-bg-opacity="1"
                        data-hover-bg=""
                        data-hover-bg-opacity="1"
                        data-animation=""
                        data-delay="0"
                      >
                        <div class="vc_column-inner">
                          <div
                            class="column-bg-overlay-wrap"
                            data-bg-animation="none"
                          >
                            <div class="column-bg-overlay"></div>
                          </div>
                          <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element  policy-text">
                              <div class="wpb_wrapper">
                                <h3>2 – User Responsibilities</h3>
                                <p>When using the Site, you agree to:</p>
                                <ul>
                                  <li>
                                    Comply with all applicable laws and
                                    regulations.
                                  </li>
                                  <li>
                                    Use the Site for lawful purposes only and not
                                    engage in any activity that may harm, disrupt,
                                    or interfere with the functioning of the Site
                                    or the experience of other users.
                                  </li>
                                  <li>
                                    Provide accurate and complete information when
                                    required, such as during registration or when
                                    making a purchase
                                  </li>
                                  <li>
                                    Protect your login credentials and not share
                                    your account information with others.
                                  </li>
                                  <li>
                                    Refrain from unauthorized use of any
                                    third-party accounts or impersonating any
                                    person or entity
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="pickItem03"
                    data-midnight=""
                    data-column-margin="default"
                    class="wpb_row vc_row-fluid vc_row inner_row standard_section   "
                    style={{ paddingTop: "0px", paddingBottom: "30px" }}
                  >
                    <div class="row-bg-wrap">
                      {" "}
                      <div class="row-bg"></div>{" "}
                    </div>
                    <div class="row_col_wrap_12_inner col span_12  left">
                      <div
                        class="vc_col-sm-12 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone instance-6"
                        data-t-w-inherits="default"
                        data-bg-cover=""
                        data-padding-pos="all"
                        data-has-bg-color="false"
                        data-bg-color=""
                        data-bg-opacity="1"
                        data-hover-bg=""
                        data-hover-bg-opacity="1"
                        data-animation=""
                        data-delay="0"
                      >
                        <div class="vc_column-inner">
                          <div
                            class="column-bg-overlay-wrap"
                            data-bg-animation="none"
                          >
                            <div class="column-bg-overlay"></div>
                          </div>
                          <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element  policy-text">
                              <div class="wpb_wrapper">
                                <h3>3 – Limitation of Liability</h3>
                                <p>
                                  Nafsika Inc. and its affiliates, directors,
                                  officers, employees, agents, suppliers, or
                                  licensors shall not be liable for any indirect,
                                  incidental, special, consequential, or punitive
                                  damages, including without limitation, loss of
                                  profits, data, use, goodwill, or other
                                  intangible losses, resulting from:
                                </p>
                                <ul>
                                  <li>Your access to or use of the Site.</li>
                                  <li>
                                    Any conduct or content of any third party on
                                    the Site.
                                  </li>
                                  <li>
                                    Any unauthorized access to or alteration of
                                    your transmissions or data.
                                  </li>
                                  <li>
                                    Any errors, inaccuracies, or omissions in the
                                    content and materials on the Site.
                                  </li>
                                  <li>
                                    Any loss or damage arising from viruses,
                                    malware, or other harmful components
                                    transmitted through the Site.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="pickItem04"
                    data-midnight=""
                    data-column-margin="default"
                    class="wpb_row vc_row-fluid vc_row inner_row standard_section   "
                    style={{ paddingTop: "0px", paddingBottom: "30px" }}
                  >
                    <div class="row-bg-wrap">
                      {" "}
                      <div class="row-bg"></div>{" "}
                    </div>
                    <div class="row_col_wrap_12_inner col span_12  left">
                      <div
                        class="vc_col-sm-12 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone instance-7"
                        data-t-w-inherits="default"
                        data-bg-cover=""
                        data-padding-pos="all"
                        data-has-bg-color="false"
                        data-bg-color=""
                        data-bg-opacity="1"
                        data-hover-bg=""
                        data-hover-bg-opacity="1"
                        data-animation=""
                        data-delay="0"
                      >
                        <div class="vc_column-inner">
                          <div
                            class="column-bg-overlay-wrap"
                            data-bg-animation="none"
                          >
                            <div class="column-bg-overlay"></div>
                          </div>
                          <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element  policy-text">
                              <div class="wpb_wrapper">
                                <h3>4 – Governing Law</h3>
                                <p>
                                  These Terms of Use shall be governed by and
                                  construed in accordance with the laws of the
                                  jurisdiction where Nafsika Inc. is registered,
                                  without regard to its conflict of law
                                  provisions.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="pickItem05"
                    data-midnight=""
                    data-column-margin="default"
                    class="wpb_row vc_row-fluid vc_row inner_row standard_section   "
                    style={{ paddingTop: "0px", paddingBottom: "30px" }}
                  >
                    <div class="row-bg-wrap">
                      {" "}
                      <div class="row-bg"></div>{" "}
                    </div>
                    <div class="row_col_wrap_12_inner col span_12  left">
                      <div
                        class="vc_col-sm-12 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone instance-8"
                        data-t-w-inherits="default"
                        data-bg-cover=""
                        data-padding-pos="all"
                        data-has-bg-color="false"
                        data-bg-color=""
                        data-bg-opacity="1"
                        data-hover-bg=""
                        data-hover-bg-opacity="1"
                        data-animation=""
                        data-delay="0"
                      >
                        <div class="vc_column-inner">
                          <div
                            class="column-bg-overlay-wrap"
                            data-bg-animation="none"
                          >
                            <div class="column-bg-overlay"></div>
                          </div>
                          <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element  policy-text">
                              <div class="wpb_wrapper">
                                <h3>5 – Changes to Terms of Use</h3>
                                <p>
                                  Nafsika Inc. reserves the right to update or
                                  modify these Terms of Use at any time without
                                  prior notice. Any changes to the Terms of Use
                                  will be effective immediately upon posting on
                                  the Site. Your continued use of the Site after
                                  the posting of any changes constitutes your
                                  acceptance of such changes.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="pickItem06"
                    data-midnight=""
                    data-column-margin="default"
                    class="wpb_row vc_row-fluid vc_row inner_row standard_section   "
                    style={{ paddingTop: "0px", paddingBottom: "30px" }}
                  >
                    <div class="row-bg-wrap">
                      {" "}
                      <div class="row-bg"></div>{" "}
                    </div>
                    <div class="row_col_wrap_12_inner col span_12  left">
                      <div
                        class="vc_col-sm-12 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone instance-9"
                        data-t-w-inherits="default"
                        data-bg-cover=""
                        data-padding-pos="all"
                        data-has-bg-color="false"
                        data-bg-color=""
                        data-bg-opacity="1"
                        data-hover-bg=""
                        data-hover-bg-opacity="1"
                        data-animation=""
                        data-delay="0"
                      >
                        <div class="vc_column-inner">
                          <div
                            class="column-bg-overlay-wrap"
                            data-bg-animation="none"
                          >
                            <div class="column-bg-overlay"></div>
                          </div>
                          <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element  policy-text">
                              <div class="wpb_wrapper">
                                <h3>6 – Termination</h3>
                                <p>
                                  We implement reasonable security measures to
                                  protect your personal information from
                                  unauthorized access, loss, misuse, or
                                  alteration. However, please note that no method
                                  of transmission over the internet or electronic
                                  storage is 100% secure.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="pickItem07"
                    data-midnight=""
                    data-column-margin="default"
                    class="wpb_row vc_row-fluid vc_row inner_row standard_section   "
                    style={{ paddingTop: "0px", paddingBottom: "30px" }}
                  >
                    <div class="row-bg-wrap">
                      {" "}
                      <div class="row-bg"></div>{" "}
                    </div>
                    <div class="row_col_wrap_12_inner col span_12  left">
                      <div
                        class="vc_col-sm-12 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone instance-10"
                        data-t-w-inherits="default"
                        data-bg-cover=""
                        data-padding-pos="all"
                        data-has-bg-color="false"
                        data-bg-color=""
                        data-bg-opacity="1"
                        data-hover-bg=""
                        data-hover-bg-opacity="1"
                        data-animation=""
                        data-delay="0"
                      >
                        <div class="vc_column-inner">
                          <div
                            class="column-bg-overlay-wrap"
                            data-bg-animation="none"
                          >
                            <div class="column-bg-overlay"></div>
                          </div>
                          <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element  policy-text">
                              <div class="wpb_wrapper">
                                <h3>7 – Severability</h3>
                                <p>
                                  Nafsika Inc. reserves the right, in its sole
                                  discretion, to terminate or suspend your access
                                  to the Site, without prior notice or liability,
                                  for any reason whatsoever, including without
                                  limitation if you breach the Terms of Use
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="pickItem08"
                    data-midnight=""
                    data-column-margin="default"
                    class="wpb_row vc_row-fluid vc_row inner_row standard_section   "
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <div class="row-bg-wrap">
                      {" "}
                      <div class="row-bg"></div>{" "}
                    </div>
                    <div class="row_col_wrap_12_inner col span_12  left">
                      <div
                        class="vc_col-sm-12 wpb_column column_container vc_column_container col child_column no-extra-padding inherit_tablet inherit_phone instance-11"
                        data-t-w-inherits="default"
                        data-bg-cover=""
                        data-padding-pos="all"
                        data-has-bg-color="false"
                        data-bg-color=""
                        data-bg-opacity="1"
                        data-hover-bg=""
                        data-hover-bg-opacity="1"
                        data-animation=""
                        data-delay="0"
                      >
                        <div class="vc_column-inner">
                          <div
                            class="column-bg-overlay-wrap"
                            data-bg-animation="none"
                          >
                            <div class="column-bg-overlay"></div>
                          </div>
                          <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element  policy-text">
                              <div class="wpb_wrapper">
                                <h3>8 – Entire Agreement</h3>
                                <p>
                                  These Terms of Use, along with the Privacy
                                  Policy, constitute the entire agreement between
                                  Nafsika Inc. and you regarding your use of the
                                  Site and supersede all prior and contemporaneous
                                  agreements, representations, and understandings
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer mt={false} />
    </>
  );
};

export default TermsConditions;
