import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { getImageBaseUrl } from '../env.Config';
import myVideo from '../assets/images/plantbased.mp4';
import Section5 from './Section5';

function Section3({ recipes, for_you, chapters }) {


    const videoRef = useRef(null);
    const [isMuted, setIsMuted] = useState(true); // Assuming you start with muted video

    useEffect(() => {
        const video = videoRef.current;

        const handleEnded = () => {
            video.currentTime = 0; // Reset playback position to the start
            video.play(); // Play the video again
        };

        video.addEventListener('ended', handleEnded);

        return () => {
            video.removeEventListener('ended', handleEnded);
        };
    }, []);

    useEffect(() => {
        // Get the video element after it has been rendered
        const video = document.getElementById("myVideo");

        // Remove the controls attribute
        video.removeAttribute("controls");

        // Add an event listener to prevent default behavior on touch events
        video.addEventListener("touchstart", function (e) {
            e.preventDefault();
            if (video.paused) {
                video.play();
            }
        });

        // Clean up: remove event listener when component unmounts
        return () => {
            video.removeEventListener("touchstart", () => { });
        };
    }, []);

        // Function to toggle mute state
        const toggleMute = () => {
            const currentMuteState = !isMuted;
            setIsMuted(currentMuteState);
            videoRef.current.muted = currentMuteState;
        };

    return (
        <section className='py-4 py-md-5' id='recipes-main'>
            <Container>
                <h2 className='sec-title pt-0 pt-md-4'>
                    <span className='text-underline'> Get </span>Plant Based Recipes
                </h2>
                <Row className='mt-4 mt-md-4 pb-4 g-4'>
                    {recipes.map((recipe, index) => (
                        <Col xs={12} md={6} lg={4} xl={3} className='mb-2 mb-lg-0' key={index}>
                            <Card>
                                <Card.Body>
                                    <div className="recipes-box">
                                        <img src={getImageBaseUrl() + "/" + recipe.recipes_images} className='recipes' alt={recipe.title} />
                                    </div>
                                </Card.Body>
                                <Card.Footer>
                                    <p className='mb-0'>{recipe.title}</p>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row className='mt-4 mt-lg-5 align-items-center mb-0'>
                    <Col md={12} lg={12}>
                        {/* <div className='recipes-banner'>
                            <img src={getImageBaseUrl() + "/" + for_you?.best_for_you_image} alt="" />
                        </div> */}
                        {/* <div className='recipes-banner'>
                            {myVideo && <video width="100%" height="100%"  autoplay>
                                <source src={myVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            }
                        </div> */}
                    </Col>
                    <div className='recipes-banner video-main'>
                        {myVideo && (
                            <video width="100%" id="myVideo" height="100%" ref={videoRef} controls autoPlay playsInline muted={true} style={{ objectFit: 'cover' }} className='mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0'>
                                <source src={myVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        <button  className='mutetd-btn' style={{ background: 'transparent', border: 'none', padding: 0 }}>
                            {isMuted ?  <img src="../images/png/sound_off.png" alt="" onClick={toggleMute} />: <img onClick={toggleMute} src="../images/png/sound_on.png" alt="" /> }
                        </button>
                    </div>
                    {/* <Col className='pt-4 pt-lg-0 ps-lg-5' md={12} lg={6}>
                        <h5 className='best-title'> <span className='text-underline'> Best</span> For You</h5>
                        <p className='best-description'>{for_you?.description}</p>
                        <div className='d-flex align-items-center text-center gap-0 gap-md-3 justify-content-between justify-content-md-start'>
                            <div className='me-0 me-md-3 circle-icons'>
                                <img src="../images/png/category-icon-1.png" alt="" />
                                <p className='category-icon-text'>Protein</p>
                            </div>
                            <div className='me-0 me-md-3 circle-icons'>
                                <img src="../images/png/category-icon-3.png" alt="" />
                                <p className='category-icon-text'>Calcium</p>
                            </div>
                            <div className='me-0 me-md-3 circle-icons'>
                                <img src="../images/png/category-icon-2.png" alt="" />
                                <p className='category-icon-text'>No chemical</p>
                            </div>
                        </div>
                    </Col> */}
                </Row>
                <Section5 chapters={chapters} />

            </Container>
        </section>
    );
}

export default Section3;
